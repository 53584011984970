<template>
  <b-form class="form" v-on:submit.prevent="onSubmit">
    <h4 class="font-weight-bolder mb-5"></h4>

    <span class="d-flex mb-5 align-items-center">
      <span class="h5 font-weight-bolder">{{
        $t("LEAVE.LEAVEAPPLICATION")
      }}</span>
    </span>
    <b-form-group
      id="type"
      label-cols-lg="2"
      :label="$t('ENTITLEMENT.TYPE')"
      label-for="type"
    >
      <b-form-select
        id="type"
        v-model="$v.form.type.$model"
        :options="type"
        :state="validateState('type')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.type.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="balance"
      label-cols-lg="2"
      label-for="balance"
      :label="$t('ENTITLEMENT.BALANCE')"
    >
      <b-form-input
        id="balance"
        :placeholder="$t('ENTITLEMENT.BALANCE1')"
        v-model="$v.form.balance.$model"
        :state="validateState('balance')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.balance.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-for="from"
      label-cols-lg="2"
      :label="$t('EDUCATION.FROM')"
    >
      <b-form-datepicker
        id="from"
        class="form-control h-auto"
        v-model="$v.form.from.$model"
        :state="validateState('from')"
      >
      </b-form-datepicker>
    </b-form-group>

    <b-form-group label-for="to" label-cols-lg="2" :label="$t('EDUCATION.TO')">
      <b-form-datepicker
        id="to"
        class="form-control h-auto"
        v-model="$v.form.to.$model"
        :state="validateState('to')"
      >
      </b-form-datepicker>
    </b-form-group>

    <b-form-group
      id="days"
      label-cols-lg="2"
      :label="$t('ENTITLEMENT.DAY')"
      label-for="days"
    >
      <b-form-select
        id="days"
        v-model="$v.form.days.$model"
        :options="days"
        :state="validateState('days')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.days.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="session"
      label-cols-lg="2"
      label-for="session"
      :label="$t('ENTITLEMENT.SESSION')"
    >
      <b-form-input
        id="session"
        :placeholder="$t('ENTITLEMENT.SESSION1')"
        v-model="$v.form.session.$model"
        :state="validateState('session')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.session.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      class="uppy-Root uppy-FileInput-container"
      label-cols-lg="2"
      :label="$t('ENTITLEMENT.ATTACHMENT')"
    >
      <b-form-file
        class="uppy-FileInput-input uppy-input-control"
        type="file"
        name="files[]"
        multiple=""
        id="kt_uppy_5_input_control"
        style=""
      />
    </b-form-group>

    <b-form-group
      id="reason"
      label-cols-lg="2"
      label-for="reason"
      :label="$t('ENTITLEMENT.REASON')"
    >
      <b-form-textarea
        id="reason"
        v-model="$v.form.reason.$model"
        :placeholder="$t('ENTITLEMENT.REASON1')"
        :state="validateState('reason')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.reason.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <span class="d-flex mb-5 align-items-center">
      <span class="h5 font-weight-bolder">{{ $t("ENTITLEMENT.PURPOSE") }}</span>
    </span>

    <b-form-group
      id="remark"
      label-cols-lg="2"
      label-for="remark"
      :label="$t('JOB.REMARK')"
    >
      <b-form-textarea
        id="remark"
        v-model="$v.form.remark.$model"
        :placeholder="$t('JOB.REMARK1')"
        :state="validateState('remark')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.remark.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-for="application"
      label-cols="2"
      :label="$t('ENTITLEMENT.APPLICATION')"
    >
      <b-form-datepicker
        id="application"
        class="form-control h-auto"
        v-model="$v.form.application.$model"
        :state="validateState('application')"
      >
      </b-form-datepicker>
      <b-form-invalid-feedback v-if="!$v.form.application.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      id="status"
      label-cols="2"
      label-for="status"
      :label="$t('JOB.STATUS')"
    >
      <b-form-select
        id="status"
        v-model="$v.form.status.$model"
        :options="status"
        :state="validateState('status')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.status.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>

    <div class="d-flex justify-content-end border-top pt-3">
      <b-button type="reset" variant="light" class="mr-2 font-size-h6">
        {{ $t("EMPLOYEES.DETAILS.RESET") }}</b-button
      >

      <button
        ref="employee-general-button"
        class="btn btn-primary text-white font-weight-light font-size-h6"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import swal from "sweetalert";

export default {
  name: "LeavePageApplicationPendingForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Leave Pending Application Form",
    },
  },
  data() {
    return {
      form: {
        type: "",
        balance: "",
        from: "",
        to: "",
        days: "",
        session: "",
        reason: "",
        remark: "",
        application: "",
        status: "",
      },
      type: [
        { value: "1", text: "Annual" },
        { value: "2", text: "Medical" },
        { value: "3", text: "Emergency" },
      ],
      status: [
        { value: "1", text: "Approve" },
        { value: "2", text: "Reject" },
      ],
      image: null,
      image_url: "",
      formBusy: false,
      imageBusy: false,
      mainProps: { blank: true, width: 200, height: 200, class: "m1" },
    };
  },
  validations: {
    form: {
      type: {
        required,
      },
      balance: {
        required,
        integer,
      },
      from: {
        required,
      },
      to: {
        required,
      },
      days: {
        required,
        integer,
      },
      session: {
        required,
      },
      attachment: {
        required,
      },
      reason: {
        required,
      },
      remark: {
        required,
      },
      application: { required },
      status: { required },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Application Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.formBusy = !this.formBusy;
      this.$store
        .dispatch("submitPendingApplication", this.form)
        .then(() => {
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.formBusy = !this.formBusy));
    },
    uploadPicture() {
      this.imageBusy = !this.imageBusy;
      this.$store
        .dispatch("uploadEmployeeImage", this.image)
        .then((res) => {
          console.log(res.data);
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
          this.image_url = res.data.url;
        })
        .finally(() => (this.imageBusy = !this.imageBusy));
    },
  },
  mounted() {
    this.$store.dispatch("fetchLeaveApplicationForm").then((response) => {
      const formData = response.data.data;

      console.log("Employment date", formData.date);
      this.form = {
        id: formData.id,
        type: formData.type,
        balance: formData.balance,
        from: formData.from,
        to: formData.to,
        days: formData.days,
        session: formData.session,
        attachment: formData.attachment,
        reason: formData.reason,
        remark: formData.remark,
        application: formData.application,
        status: formData.status,
      };
      this.image_url = formData.image_url;
      console.log("Image URL is", this.image_url);
    });
  },
  computed: {
    ...mapState({
      employeeId: (state) =>
        state.CompanyModule.employee.EmployeeDetails.DetailsForm.employeeId,
    }),
  },
};
</script>

<style scoped></style>
