<template>
  <b-modal
    id="leave-page-pending-modal"
    :title="$t('LEAVE.LEAVEAPPLICATION')"
    title-class="h4 font-weight-bold"
    lazy
    size="xl"
    hide-footer
  >
    <div class="d-flex px-3 py-5 mt-5 flex-column">
      <LeavePageApplicationPendingForm></LeavePageApplicationPendingForm>
    </div>
  </b-modal>
</template>

<script>
import LeavePageApplicationPendingForm from "@/modules/company/components/leave/application/LeavePageApplicationPendingForm";
export default {
  name: "LeavePageApplicationPendingModal",
  components: { LeavePageApplicationPendingForm },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
};
</script>

<style scoped></style>
