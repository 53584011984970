<template>
  <b-card>
    <b-table
      ref="statistics-list-dt"
      id="statistics-list-dt"
      responsive="sm"
      :fields="fields"
      table-class="table-head-custom table-vertical-center mt-5"
    >
    </b-table>
    <!-- begin: header Content     -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("STATISTICS.WEEK1") }}
        </h3>
      </div>
    </div>
    <b-tabs pills class="mt-5 pt-2" nav-class="nav-light-primary">
      <b-tab :title="$t('ECOMMERCE.COMMON.ALL')" lazy>
        <div class="d-flex mt-2 flex-column">
          <LeavePageStatisticsAll></LeavePageStatisticsAll>
        </div>
      </b-tab>

      <b-tab :title="$t('ECOMMERCE.COMMON.PENDING')" lazy>
        <div class="d-flex mt-2 flex-column">
          <LeavePageStatisticsPending></LeavePageStatisticsPending>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import LeavePageStatisticsAll from "@/modules/company/components/leave/statistics/LeavePageStatisticsAll";
import LeavePageStatisticsPending from "@/modules/company/components/leave/statistics/LeavePageStatisticsPending";
export default {
  name: "LeavePageApplication",
  components: { LeavePageStatisticsAll, LeavePageStatisticsPending },
};
</script>

<style scoped></style>
